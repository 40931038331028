import { format, formatDistance, isToday, isTomorrow, parse, parseISO } from 'date-fns';

// See https://date-fns.org/v2.25.0/docs/format
export const dateFormats = {
  dateMonth: 'MMMM do', // e.g. October 1st, August 5th
  longDateFormat: `yyyy-MM-dd'T'HH:mm:ss'.'SSS'Z'`, // e.g. 2021-05-02T14:23:13.012Z
  yearMonthDate: `yyyy-MM-dd`, // e.g. 2021-12-23
  hourMinute: `HH:mm`, // e.g. 23:05
  dateMonthYear: `do MMMM Y`, // e.g. 23rd November 2021
  hourMinuteAmPm: `h:mmaaa`, // e.g. 3:05pm, 11:12am
  dateDay: `EEEE`, // e.g. Tuesday
  dayDateMonthYear: 'EEEE do MMMM Y', // e.g. Saturday 21st October 2023
} as const;

export type DateFormat = (typeof dateFormats)[keyof typeof dateFormats];

export const formatDate = (date: Date, dateFormat: DateFormat): string => {
  return format(date, dateFormat);
};

export const parseDate = (date: string, dateFormat: DateFormat): Date => {
  return parse(date, dateFormat, new Date());
};

export const isTodayOrTomorrow = (date: Date | undefined): 'today' | 'tomorrow' | undefined => {
  if (date === undefined) {
    return undefined;
  }

  if (isToday(date)) {
    return 'today';
  }

  if (isTomorrow(date)) {
    return 'tomorrow';
  }

  return undefined;
};

export const isOtherDate = (date: Date): string => formatDate(date, dateFormats.dateDay);

export const transformDate = (date: Date | string): Date => (date instanceof Date ? date : parseISO(date));

export const timeAgo = (date: Date): string => formatDistance(new Date(), date);
