import deepMapKeys from 'deep-map-keys';
import type { CamelCasedProperties, CamelCasedPropertiesDeep, SetOptional } from 'type-fest';

import { dateFormats, formatDate, isTodayOrTomorrow, parseDate } from '@shared/helpers/dates/dateFormatting';
import { isSnakeCase } from '@shared/helpers/strings/isSnake';
import { toCamel } from '@shared/helpers/strings/toCamel';

interface DeliverySlotDTO {
  delivery_slot_id: number;
  start_time: string;
  end_time: string;
  delivery_date: string;
  available: boolean;
}

type DeliverySlot = CamelCasedProperties<DeliverySlotDTO>;

export type AvailabilityDTO = {
  available: boolean;
  order_hold_minutes: number;
  dates: { [date: string]: DeliverySlotDTO[] };
};

interface IAvailability extends Omit<CamelCasedPropertiesDeep<AvailabilityDTO>, 'dates'> {
  datesSlots: { [date: string]: DeliverySlot[] };
}

export class Availability {
  available!: boolean;
  orderHoldMinutes!: number;
  datesSlots!: { [date: string]: DeliverySlot[] };

  constructor(availability: IAvailability) {
    Object.assign(this, availability);
  }

  get dates(): Date[] {
    return Object.keys(this.datesSlots).map((date) => parseDate(date, dateFormats.yearMonthDate));
  }

  static get defaultTodayTomorrowParams() {
    return {
      todayText: 'Today',
      tomorrowText: 'Tomorrow',
    };
  }

  selectDates(
    { todayText, tomorrowText } = Availability.defaultTodayTomorrowParams,
  ): { date: Date; value: string; content: string }[] {
    return this.dates.map((date) => {
      const todayOrTomorrow = isTodayOrTomorrow(date);
      const todayOrTomorrowText =
        todayOrTomorrow &&
        {
          today: ` (${todayText})`,
          tomorrow: ` (${tomorrowText})`,
        }[todayOrTomorrow];

      return {
        date,
        content: formatDate(date, dateFormats.dayDateMonthYear) + (todayOrTomorrowText || ''),
        value: formatDate(date, dateFormats.yearMonthDate),
      };
    });
  }

  slotsPresentForDate(date: string): boolean {
    return Boolean((this.datesSlots[date]?.length ?? 0) > 0);
  }

  slotsForDate(date: string): DeliverySlot[] {
    return this.datesSlots[date] ?? [];
  }

  selectSlotsForDate(date: string): { value: string; content: string }[] {
    return this.slotsForDate(date).map((slot) => ({
      value: String(slot.deliverySlotId),
      content: `${formatDate(
        parseDate(slot.startTime, dateFormats.longDateFormat),
        dateFormats.hourMinuteAmPm,
      )} - ${formatDate(parseDate(slot.endTime, dateFormats.longDateFormat), dateFormats.hourMinuteAmPm)}`,
    }));
  }
}

export function mapAvailabilityDTO(response: AvailabilityDTO): Availability {
  const camelised: SetOptional<CamelCasedPropertiesDeep<AvailabilityDTO>, 'dates'> = deepMapKeys(
    response,
    (key) => (isSnakeCase(key) ? toCamel(key) : key), // date keys are in kebab case and should not be transformed
  );

  const { dates } = camelised;

  delete camelised.dates;

  const availabilityParams: IAvailability = {
    ...camelised,
    datesSlots: { ...dates },
  };

  return new Availability(availabilityParams);
}
